<template>
  <a-page-header
    @back="() => $router.go(-1)"
    title="POBLACIÓN SEGÚN PADRÓN NOMINAL DE GESTANTES Y NIÑOS MENORES DE 5 AÑOS"
    class="pt-0 px-0"
  >
    <template #extra>
      <div class="flex flex-wrap items-center gap-3">
        <a-breadcrumb>
          <a-breadcrumb-item><router-link to="/menu">Estadística</router-link></a-breadcrumb-item>
          <a-breadcrumb-item>Cantidad porcentual consolidado</a-breadcrumb-item>
        </a-breadcrumb>
      </div>
    </template>
  </a-page-header>
  <div class="flex flex-col gap-3 lg:flex-row">
    <div class="w-full lg:w-1/3">
      <a-card size="small">
        <template #title>
          <div class="text-center flex justify-between items-center px-3 py-1">
            <p class="font-semibold">DATOS POR PROVINCIA</p>
            <div class="flex gap-3">
              <a-tooltip>
                <template #title>Traer de nuevo todos los datos</template>
                <a-button shape="circle" @click="resetAllFetch">
                  <template #icon><ReloadOutlined /></template>
                </a-button>
              </a-tooltip>

              <a-tooltip>
                <template #title>Descargar datos por provincia</template>
                <a-button
                  shape="circle"
                  v-print="printObj"
                  class="text-red-500 hover:text-red-300"
                  @click="filterToPrint('provincia')"
                >
                  <template #icon><FilePdfFilled /></template>
                </a-button>
              </a-tooltip>

              <a-tooltip>
                <template #title>Descargar datos por provincia</template>
                <a-button
                  shape="circle"
                  @click="handleProvincia"
                  class="text-green-500 hover:text-green-300"
                >
                  <template #icon><FileExcelFilled /></template>
                </a-button>
              </a-tooltip>
            </div>
          </div>
        </template>

        <div class="overflow-x-auto w-full">
          <a-table
            :columns="columns1"
            :data-source="data1"
            size="small"
            :pagination="true"
            :custom-row="customRow"
            rowKey="nombre"
            :loading="isLoading1"
            :rowClassName="
              (_record, index) =>
                index % 2 === 0 ? 'cursor-pointer bg-white' : 'cursor-pointer bg-gray-100'
            "
          >
            <template #rangepages="{ record }">
              <span> {{ formateaDataProvincia.indexOf(record) + 1 }} </span>
            </template>

            <template #porcentaje="{ record }">
              <p class="text-center font-bold">{{ record.porcentaje }} %</p>
            </template>
          </a-table>
        </div>
      </a-card>
    </div>

    <div class="w-full lg:w-1/3">
      <a-card size="small">
        <template #title>
          <div class="text-center flex justify-between items-center px-3 py-1">
            <p class="font-semibold">DATOS POR DISTRITO</p>
            <div class="flex gap-3">
              <a-tooltip>
                <template #title>Descargar datos por distrito</template>
                <a-button
                  shape="circle"
                  v-print="printObj"
                  class="text-red-500 hover:text-red-300"
                  @click="filterToPrint('distrito')"
                >
                  <template #icon><FilePdfFilled /></template>
                </a-button>
              </a-tooltip>

              <a-tooltip>
                <template #title>Descargar datos por distrito</template>
                <a-button
                  shape="circle"
                  class="text-green-500 hover:text-green-300"
                  @click="handleDistrito"
                >
                  <template #icon><FileExcelFilled /></template>
                </a-button>
              </a-tooltip>
            </div>
          </div>
        </template>

        <div class="overflow-x-auto w-full">
          <a-table
            :columns="columns2"
            :data-source="data2"
            size="small"
            :custom-row="customRow2"
            :pagination="true"
            rowKey="nombre"
            :loading="isLoading2"
            :rowClassName="
              (record, index) =>
                index % 2 === 0 ? 'cursor-pointer bg-white' : 'cursor-pointer bg-gray-100'
            "
          >
            <template #rangepages="{ record }">
              <span> {{ formateaDataDistrito.indexOf(record) + 1 }} </span>
            </template>

            <template #porcentaje="{ record }">
              <p class="text-center font-bold">{{ record.porcentaje }} %</p>
            </template>
          </a-table>
        </div>
      </a-card>
    </div>

    <div class="w-full lg:w-1/3">
      <a-card size="small">
        <template #title>
          <div class="text-center flex justify-between items-center px-3 py-1">
            <p class="font-semibold">DATOS POR ESTABLECIMIENTO</p>
            <div class="flex gap-3">
              <a-tooltip>
                <template #title>Descargar datos por establecimiento</template>
                <a-button
                  shape="circle"
                  v-print="printObj"
                  class="text-red-500 hover:text-red-300"
                  @click="filterToPrint('establecimiento')"
                >
                  <template #icon><FilePdfFilled /></template>
                </a-button>
              </a-tooltip>

              <a-tooltip>
                <template #title>Descargar datos por establecimiento</template>
                <a-button
                  shape="circle"
                  class="text-green-500 hover:text-green-300"
                  @click="handleEstablecimiento"
                >
                  <template #icon><FileExcelFilled /></template>
                </a-button>
              </a-tooltip>
            </div>
          </div>
        </template>
        <div class="overflow-x-auto w-full">
          <a-table
            :columns="columns4"
            :data-source="data4"
            size="small"
            :pagination="true"
            rowKey="nombre"
            :loading="isLoading3"
            :rowClassName="(_record, index) => (index % 2 === 0 ? 'bg-white' : 'bg-gray-100')"
          >
            <template #rangepages="{ record }">
              <span> {{ formateaEstablecimiento.indexOf(record) + 1 }} </span>
            </template>

            <template #porcentaje="{ record }">
              <p class="text-center font-bold">{{ record.porcentaje }} %</p>
            </template>
          </a-table>
        </div>
      </a-card>
    </div>
  </div>

  <p class="text-gray-400 my-4">Fuente: HISMINSA actualizado a la fecha: {{ fechaIngresoTabla }}</p>

  <PrintCantidadPorcentual
    tituloReporte="CANTIDAD PORCENTUAL CONSOLIDADO DE NIÑOS Y GESTANTES"
    :dataToPrint="arraySelectPrint"
    :tituloDeFiltro="tituloDeFiltro"
    :fechaIngresoTabla="fechaIngresoTabla"
  />
</template>

<script>
import { ref, reactive, computed, onMounted } from "vue";
import { useStore } from "vuex";
import PacienteReporteApi from "@/api/pacientereporte";
import { calculaPorcentaje } from "@/utils";
import { columns1, columns2, columns3, columns4 } from "./utilsCantidadPorcentual";
import PrintCantidadPorcentual from "@/print/PrintCantidadPorcentual.vue";
import ApiDescargas from "@/api/descargas";
import UsuariosApi from "@/api/user";
import moment from "moment";
import {
  // CloudDownloadOutlined,
  // DownloadOutlined,
  FileExcelFilled,
  ReloadOutlined,
  FilePdfFilled
} from "@ant-design/icons-vue";

export default {
  components: {
    PrintCantidadPorcentual,
    // icons
    // CloudDownloadOutlined,
    // DownloadOutlined,
    FileExcelFilled,
    ReloadOutlined,
    FilePdfFilled
  },
  setup() {
    const store = useStore();
    const isLoading1 = ref(false);
    const isLoading2 = ref(false);
    const isLoading3 = ref(false);
    const data1 = ref([]);
    const data2 = ref([]);
    const data3 = ref([]);
    const data4 = ref([]);
    const activeKey = ref("1");
    const { establecimiento } = store.state.user.usuario ? store.state.user.usuario : "";
    const arraySelectPrint = ref([]);
    const tituloDeFiltro = ref("");
    const fechaIngresoTabla = ref("");
    const dataToPrint = reactive({
      provincia: [],
      distrito: [],
      centroPoblado: [],
      establecimiento: []
    });

    const printObj = reactive({
      id: "printCantidadPorcentual",
      popTitle: "SIVINUR | CANTIDAD PORCENTUAL CONSOLIDADO"
    });

    const state = reactive({
      selectedRowKeys: []
    });

    const state2 = reactive({
      selectedRowKeys: []
    });

    const reloadFetchProvincia = () => {
      isLoading1.value = true;
      PacienteReporteApi.getAllProvincia()
        .then(async (response) => {
          // console.log("PacienteReporteApi", response);
          const totalDenominador = response.data.reduce((a, b) => a + Number(b.total), 0);
          const conPorcentajes = response.data.map((p) => ({
            ...p,
            porcentaje: calculaPorcentaje(p.total, totalDenominador)
          }));
          // console.log(conPorcentjes);
          data1.value = conPorcentajes;
          dataToPrint.provincia = conPorcentajes;
        })
        .catch((err) => console.log(err))
        .finally(() => {
          isLoading1.value = false;
        });
    };

    const reloadFetchDistrito = () => {
      const provincia = store.getters["pacientereporte/provincia"];
      isLoading2.value = true;
      PacienteReporteApi.getAllDistrito(provincia)
        .then(async (response) => {
          const totalDenominador = response.data.reduce((a, b) => a + Number(b.total), 0);

          const conPorcentajes = response.data.map((p) => ({
            ...p,
            porcentaje: calculaPorcentaje(p.total, totalDenominador)
          }));
          data2.value = conPorcentajes;
          dataToPrint.distrito = conPorcentajes;
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading2.value = false));
    };

    const reloadFetchCentroPoblado = () => {
      const provincia = store.getters["pacientereporte/provincia"];
      const distrito = store.getters["pacientereporte/distrito"];
      isLoading3.value = true;
      PacienteReporteApi.getAllCentroPoblado(provincia, distrito)
        .then(async (response) => {
          const totalDenominador = response.data.reduce((a, b) => a + Number(b.total), 0);

          const conPorcentajes = response.data.map((p) => ({
            ...p,
            porcentaje: calculaPorcentaje(p.total, totalDenominador)
          }));
          data3.value = conPorcentajes;
          dataToPrint.centroPoblado = conPorcentajes;
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading3.value = false));
    };

    const reloadFetchEstablecimiento = () => {
      const provincia = store.getters["pacientereporte/provincia"];
      const distrito = store.getters["pacientereporte/distrito"];
      isLoading3.value = true;
      PacienteReporteApi.getAllEstablecimiento(provincia, distrito)
        .then(async (response) => {
          // console.log(response);
          const totalDenominador = response.data.reduce((a, b) => a + Number(b.total), 0);

          const conPorcentajes = response.data.map((p) => ({
            ...p,
            porcentaje: calculaPorcentaje(p.total, totalDenominador)
          }));
          data4.value = conPorcentajes;
          dataToPrint.establecimiento = conPorcentajes;
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading3.value = false));
    };

    const fetchFechaImportacion = () => {
      UsuariosApi.getFechaImportacion("padron_nominal_new")
        .then((res) => {
          console.log(res);
          fechaIngresoTabla.value = moment(res.data[0]?.fechaingreso).format("DD/MM/YYYY");
        })
        .catch((err) => {
          console.log(err);
        });
    };

    onMounted(() => {
      UsuariosApi.userLogAdd("Estadística", "Padrón", establecimiento);

      store.dispatch("pacientereporte/setprovincia", "");
      store.dispatch("pacientereporte/setdistrito", "");
      reloadFetchDistrito();
      reloadFetchProvincia();
      reloadFetchCentroPoblado();
      reloadFetchEstablecimiento();
      fetchFechaImportacion();
    });

    const rowSelection = computed(() => {
      return {
        type: "radio",
        selectedRowKeys: state.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          state.selectedRowKeys = selectedRowKeys;
          const info = data1.value.find((p) => p.provincia === selectedRows[0].provincia);

          store.dispatch("pacientereporte/setprovincia", info.provincia);
          reloadFetchDistrito();
        }
      };
    });

    const rowSelection2 = computed(() => {
      return {
        type: "radio",
        selectedRowKeys: state2.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          state2.selectedRowKeys = selectedRowKeys;
          const info = data2.value.find((p) => p.distrito === selectedRows[0].distrito);

          store.dispatch("pacientereporte/setdistrito", info.nombre);
          reloadFetchCentroPoblado();
          reloadFetchEstablecimiento();
        }
      };
    });
    const customRow = (record) => {
      return {
        onClick: () => {
          store.dispatch("pacientereporte/setprovincia", record.nombre);
          reloadFetchDistrito();
        },
        onDblclick: () => {
          clearSelectedArrays();
        }
      };
    };

    const customRow2 = (record) => {
      return {
        onClick: () => {
          store.dispatch("pacientereporte/setdistrito", record.nombre);
          reloadFetchCentroPoblado();
          reloadFetchEstablecimiento();
        },
        onDblclick: () => {
          clearSelectedArrays2();
        }
      };
    };

    const resetAllFetch = () => {
      store.dispatch("pacientereporte/setprovincia", "");
      store.dispatch("pacientereporte/setdistrito", "");

      reloadFetchDistrito();
      reloadFetchProvincia();
      reloadFetchCentroPoblado();
      reloadFetchEstablecimiento();
    };

    const clearSelectedArrays = () => {
      state.selectedRowKeys = [];
    };

    const clearSelectedArrays2 = () => {
      state.selectedRowKeys2 = [];
    };

    const handleProvincia = () => {
      ApiDescargas.getReportePacienteProvincia("")
        .then(async () => {})
        .catch((err) => console.log(err));
    };

    const handleDistrito = () => {
      const provincia = store.getters["pacientereporte/provincia"];

      ApiDescargas.getReportePacienteDistrito(provincia, "")
        .then(async () => {})
        .catch((err) => console.log(err));
    };

    const handleCentroPoblado = () => {
      const provincia = store.getters["pacientereporte/provincia"];
      const distrito = store.getters["pacientereporte/distrito"];

      ApiDescargas.getReportePacienteCentroPoblado(provincia, distrito, "")
        .then(async () => {})
        .catch((err) => console.log(err));
    };

    const handleEstablecimiento = () => {
      const provincia = store.getters["pacientereporte/provincia"];
      const distrito = store.getters["pacientereporte/distrito"];

      ApiDescargas.getReporteEstablecimientoo(provincia, distrito, "")
        .then(async () => {})
        .catch((err) => console.log(err));
    };

    const filterToPrint = (areaGeografica) => {
      tituloDeFiltro.value = areaGeografica;
      const areaDesordenada = dataToPrint[areaGeografica];
      arraySelectPrint.value = areaDesordenada.sort((a, b) => {
        return Number(b.porcentaje) - Number(a.porcentaje);
      });
    };

    return {
      // routes,
      data1,
      data2,
      data3,
      data4,
      handleProvincia,
      handleDistrito,
      handleCentroPoblado,
      handleEstablecimiento,
      customRow,
      customRow2,
      rowSelection,
      rowSelection2,
      resetAllFetch,
      columns1,
      columns2,
      columns3,
      columns4,
      isLoading1,
      isLoading2,
      isLoading3,
      activeKey,
      printObj,
      filterToPrint,
      dataToPrint,
      arraySelectPrint,
      tituloDeFiltro,
      fechaIngresoTabla
      // reloadFetch
    };
  }
};
</script>
